










import { Vue, Component } from "vue-property-decorator";
import VesselEnquiryExpiringTable from "@/components/vessel/enquiry/VesselEnquiryExpiringTable.vue";

@Component({ components: { VesselEnquiryExpiringTable } })
export default class ExpiringEnquiriesOverview extends Vue {}
